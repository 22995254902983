import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstagramFeedService } from 'src/app/core/services/api/instagram-feed.service';
import { carouselInstagram } from 'src/app/shared/constants/slides';

import { InstaFeedModel } from '../../models/insta-feed.model';

import * as componentsB4a from '@infrab4a/components-b4a';


@Component({
  selector: 'malmo-instagram-carousel',
  templateUrl: './instagram-carousel.component.html',
  styleUrls: ['./instagram-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstagramCarouselComponent implements OnInit {
  @Input() innerWidth: number;

  instaFeed$: Observable<InstaFeedModel[]>;
  instaFeedStaticsImages = [
    {
      image: 'assets/img/instagram/1Design---cachecol.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
    {
      image: 'assets/img/instagram/2Design---Carteira-Leif.jpg',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
    {
      image: 'assets/img/instagram/3Design---Colar-e-pulseira-perola.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
    {
      image: 'assets/img/instagram/4Design---Mix-Pulseirass-.jpg',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
    {
      image: 'assets/img/instagram/5Malmo-Porta-Acessorios-3.jpg',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
    {
      image: 'assets/img/instagram/6sem-titulo-430.jpg',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/usemalmo/'
    },
  ]

  carouselTile: componentsB4a.B4aCarouselConfig;

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel?.pointNumbers;
      });
  }
  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  constructor(private _instagramFeedService: InstagramFeedService) {
    this.carouselTile = carouselInstagram;
  }

  ngOnInit(): void {
    this.instaFeed$ = this._instagramFeedService.getFeed().pipe(
      map((feeds) => feeds.filter((data) => data.media_type !== 'VIDEO')),
      map((feeds) =>
        feeds.map((feed) => {
          return {
            ...feed,
            caption:
              feed.caption?.trim() !== ''
                ? `${feed.caption.substring(0, 240)}...`
                : '',
          };
        })
      )
    );
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }

  get skeletonTheme(): Object {
    return {
      width: this.innerWidth <= 768 ? '210px' : '210px',
      height: this.innerWidth <= 768 ? '210px' : '210px',
      'border-radius': '0px',
      'background-color': '#f2f2f2',
    };
  }

  get skeletonCount(): number {
    if (this.innerWidth <= 425) {
      return 1;
    } else if (this.innerWidth > 425 && this.innerWidth <= 585) {
      return 2;
    } else if (this.innerWidth > 585 && this.innerWidth <= 768) {
      return 3;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1366) {
      return 4;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1520) {
      return 5;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1640) {
      return 6;
    } else if (this.innerWidth > 1640 && this.innerWidth <= 1920) {
      return 7;
    } else {
      return 8;
    }
  }
}
