import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'malmo-fashion',
  templateUrl: './fashion.component.html',
  styleUrls: ['./fashion.component.scss'],
})
export class FashionComponent implements OnInit {
  isProduction = false;
  instagram = {
    username: 'usemalmo',
    token: ENV.instaToken,
  };
  innerWidth: number;
  constructor( private meta: Meta,
    private titleService: Title) {
    this.isProduction = ENV.production;
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.titleService.setTitle("Malmo: descubra o seu estilo");
    this.meta.addTag({
      name: 'author',
      content: 'B4A'
    });
    this.meta.updateTag({
      name: 'description',
      content:
        'Descubra qual o estilo mais combina com você! A Malmo oferece os melhores acessórios masculinos com uma curadoria que segue as tendências e com o melhor preço'
    });
  }
}
