<div id="malmo-insta-carrousel">
    <h2>
        <a
            href="https://www.instagram.com/usemalmo"
            target="_blank"
            rel="noopener noreferrer"
        >
            @usemalmo
        </a>
    </h2>

    <ng-container *ngIf="instaFeedStaticsImages">
      <div class="container-desktop">
          <components-b4a-carousel
              #myCarousel
              [inputs]="carouselTile"
              [dataSource]="instaFeedStaticsImages"
              (onMove)="onCarouselMove($event)"
          >
              <b4a-tile *b4aCarouselDef="let item; let i = index">
                  <div class="card">
                      <a
                          [href]="item.permalink"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          <img
                              [src]="item.image"
                              [alt]="item.alt"
                              class="card__image"
                          >
                      </a>
                  </div>
              </b4a-tile>
              <button
                  B4aCarouselPrev
                  class="prev"
                  [style.opacity]="myCarousel.isFirst ? 0.5 : 1"
              >
                  <img
                      src="../../../assets/img/svg/outline-previous-arrow.svg"
                      alt="botão voltar"
                  >
              </button>
              <button
                  B4aCarouselNext
                  class="next"
                  [style.opacity]="myCarousel.isLast ? 0.5 : 1"
              >
                  <img
                      src="../../../assets/img/svg/outline-next-arrow.svg"
                      alt="botão próximo"
                  >
              </button>
              <ul
                  class="dots"
                  B4aCarouselPoint
                  *ngIf="points?.length"
              >
                  <li
                      *ngFor="let i of points; let index"
                      [class.active]="index === myCarousel.activePoint"
                      (click)="myCarousel.moveTo(i)"
                  ></li>
              </ul>
          </components-b4a-carousel>
      </div>

      <div class="container-mobile">
          <div *ngFor="let item of instaFeedStaticsImages; let i = index;">
              <div
                  class="card"
                  *ngIf="i < 6"
              >
                  <a
                      [href]="item.permalink"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                      <img
                          [src]="item.image"
                          [alt]="item.alt"
                          class="card__image"
                      >
                  </a>
              </div>
          </div>
      </div>

  </ng-container>

    <!-- <ng-container *ngIf="instaFeed$ | async as instaFeed; else loadingCarousel">
        <div class="container-desktop">
            <components-b4a-carousel
                #myCarousel
                [inputs]="carouselTile"
                [dataSource]="instaFeed"
                (onMove)="onCarouselMove($event)"
            >
                <b4a-tile *b4aCarouselDef="let item; let i = index">
                    <div class="card">
                        <a
                            [title]="item.caption"
                            [href]="item.permalink"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                [src]="item.media_url"
                                [alt]="item.caption"
                                class="card__image"
                            >
                        </a>
                    </div>
                </b4a-tile>
                <button
                    B4aCarouselPrev
                    class="prev"
                    [style.opacity]="myCarousel.isFirst ? 0.5 : 1"
                >
                    <img
                        src="../../../assets/img/svg/outline-previous-arrow.svg"
                        alt="botão voltar"
                    >
                </button>
                <button
                    B4aCarouselNext
                    class="next"
                    [style.opacity]="myCarousel.isLast ? 0.5 : 1"
                >
                    <img
                        src="../../../assets/img/svg/outline-next-arrow.svg"
                        alt="botão próximo"
                    >
                </button>
                <ul
                    class="dots"
                    B4aCarouselPoint
                    *ngIf="points?.length"
                >
                    <li
                        *ngFor="let i of points; let index"
                        [class.active]="index === myCarousel.activePoint"
                        (click)="myCarousel.moveTo(i)"
                    ></li>
                </ul>
            </components-b4a-carousel>
        </div>

        <div class="container-mobile">
            <div *ngFor="let item of instaFeed; let i = index;">
                <div
                    class="card"
                    *ngIf="i < 6"
                >
                    <a
                        [title]="item.caption"
                        [href]="item.permalink"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            [src]="item.media_url"
                            [alt]="item.caption"
                            class="card__image"
                        >
                    </a>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-template #loadingCarousel>
        <div class="content-loading">
            <ngx-skeleton-loader
                [count]="skeletonCount"
                [theme]="skeletonTheme"
            ></ngx-skeleton-loader>
        </div>
    </ng-template> -->
</div>
