<div id="malmo-home">
    <main>
        <section id="banner">
            <malmo-slider-banner [bannerItems]="banners" *ngIf="banners"></malmo-slider-banner>
        </section>

        <section id="second-section">
            <malmo-categories></malmo-categories>
        </section>

        <section id="third-section">
            <malmo-fashion-block></malmo-fashion-block>
        </section>

        <section id="fourth-section">
            <malmo-products [products]="products"></malmo-products>
        </section>

        <section>
            <components-b4a-values
                [values]="companyValues"
                link="https://mensmarket.com.br/collections/marca-malmo"
                [newTab]="false"
            ></components-b4a-values>
        </section>

        <section>
            <malmo-reviews
                [reviewedProducts]="reviewedProducts"
                [reviewPoints]="reviewPoints"
            ></malmo-reviews>
        </section>

        <section id="fifth-section">
            <malmo-instagram-carousel [innerWidth]="innerWidth"></malmo-instagram-carousel>
        </section>

        <!-- <section id="seven-section">
            <components-b4a-newsletter
                [projectId]="this.projectId"
                [source]="this.source"
            ></components-b4a-newsletter>
        </section> -->
    </main>
</div>

<section class="modal">
    <ng-template
        #content
        let-modal
    >
        <div class="modal-body">
            <a
                type="button"
                class="close-modal"
                (click)="modal.dismiss('Cross click')"
            >
                <img
                    src="assets/img/svg/close-modal.svg"
                    alt="Close"
                >
            </a>
            <img
                class="banner-modal"
                [src]="innerWidth > 768 ? 'assets/img/modal-desktop.png' : 'assets/img/modal-mobile.png'"
                alt
            >
            <div class="aside-container">
                <div *ngIf="show">
                    <h1>Que tal 10%OFF para experimentar?</h1>
                    <p>
                        Cadastre aqui e ganhe 10% de desconto no site em sua primeira compra. Ah!
                        <strong>
                            E com qualquer assinatura do Clube da Men’s Market (a partir de R$9,90 por mês)
                        </strong>
                        você consegue 40% de desconto em produtos Men’s. Demais, né? ;)
                    </p>
                    <form
                        [formGroup]="formLead"
                        (ngSubmit)="onSubmit()"
                        novalidate
                    >
                        <div id="input-form" class="input-box">
                            <input
                                [ngClass]="getClass(formLeadControl.name)"
                                id="name"
                                type="text"
                                formControlName="name"
                                placeholder="Digite seu nome"
                            >
                            <small
                                class="danger"
                                *ngIf="(formLeadControl.name.touched || submitted) && formLeadControl.name.errors?.required"
                            >
                                Nome é obrigatório
                            </small>
                            <small
                                class="danger"
                                *ngIf="formLeadControl.name.touched && formLeadControl.name.errors?.pattern"
                            >
                                Nome inválido
                            </small>
                        </div>
                        <div
                            id="input-form"
                            class="input-box"
                        >
                            <input
                                [ngClass]="getClass(formLeadControl.email)"
                                id="email"
                                type="email"
                                formControlName="email"
                                placeholder="Digite seu e-mail"
                            >
                            <small
                                class="danger"
                                *ngIf="(formLeadControl.email.touched || submitted) && formLeadControl.email.errors?.required"
                            >
                                E-mail é obrigatório
                            </small>
                            <small
                                class="danger"
                                *ngIf="formLeadControl.email.touched && formLeadControl.email.errors?.pattern"
                            >
                                E-mail inválido
                            </small>
                        </div>
                        <button
                            type="submit"
                            class="btn-tertiary btn-submit"
                            [disabled]="loadingNewsletter || formLead.invalid"
                        >
                            {{ loadingNewsletter ? 'Aguarde' : 'Receber cupom' }}
                        </button>
                    </form>
                </div>
                <div
                    class="coupon-container"
                    *ngIf="!show"
                >
                    <h1>Oba! Cupom!</h1>
                    <p>
                        Use o cupom abaixo e garanta 10%OFF na sua primeira compra.
                    </p>
                    <img
                        class="coupon-border"
                        [src]="innerWidth > 768 ? 'assets/img/border-coupon.svg' : 'assets/img/border-coupon-mobile.svg'"
                        alt="border coupon"
                    >
                    <p class="user-email">
                        Esse desconto só é válido para o e-mail:
                        <strong>{{ user_email }}</strong>
                    </p>

                    <a
                        target="_blank"
                        href="https://mensmarket.com.br/collections/marca-malmo"
                    >
                        <button
                            type="submit"
                            class="btn-tertiary btn-submit"
                        >
                            Aproveitar
                        </button>
                    </a>
                </div>

            </div>
        </div>
    </ng-template>
</section>
