<div id="slider-banner">
    <components-b4a-carousel
        #myCarousel
        [inputs]="carouselTile"
        (onMove)="onCarouselMove($event)"
        [dataSource]="bannerItems"
    >
        <b4a-tile *b4aCarouselDef="let item; let i = index">
            <div
                class="wrapper"
                *ngIf="item"
            >
                <a 
                    [href]="item.path"
                    target="_blank"
                >
                    <img
                        [alt]="item.alt"
                        [src]="isMobile ? item.mobileImage : item.image"
                    >
                </a>
               
            </div>
        </b4a-tile>
        <ul
            class="dots"
            B4aCarouselPoint
            *ngIf="points?.length"
        >
            <li
                *ngFor="let i of points; let index"
                [class.active]="index === myCarousel.activePoint"
                (click)="myCarousel.moveTo(i)"
            ></li>
        </ul>
    </components-b4a-carousel>
</div>
